<template>
  <div class='common_box room'>
    <div class="common_title">
      <h3>网点管理-房间管理</h3>
      <div>
        <el-button class="btn_info" style="margin-right:10px" @click="addRomeVisible = true">添加房间</el-button>
        <el-button class="btn_info" style="margin-right:18px" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
    <el-form :model="suiteForm" :rules="suiterules" ref="suiteForm" class="demo-ruleForm">
      <el-table :data="suiteForm.tableData" stripe style="width: 100%">
        <el-table-column prop="name" label="房间名称">
          <template slot-scope="scope">
            <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.name'" :rules="suiterules.name">
              <el-input v-model="scope.row.name" size="small" />
            </el-form-item>
            <span v-else>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sceneName" label="所属场景">
          <template slot-scope="scope">
            <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.sceneId'" :rules="suiterules.sceneId">
              <el-select v-model="scope.row.sceneId" v-if="scope.row.editShow" placeholder="请选择" size="small">
                <el-option v-for="item in sceneList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <span v-else>{{scope.row.sceneName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="摄像头">
          <template>
            <el-button type="text" @click="$router.push('/equipment')">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="参数设置">
          <template slot-scope="{row}">
            <el-button type="text" @click="$router.push({
              path:'/system/parametersetting',
              query: {
                id: row.id
              }
            })">修改</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark" v-if="scope.row.editShow" size="small" />
            <span v-else>{{scope.row.remark? scope.row.remark: '暂无备注'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="监测状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#1890FF" inactive-color="#BABBBE" :width="50" @change="switchChange(scope.row.id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" width="100">
          <template slot-scope="scope">
            <!-- <i class="el-icon-delete"></i> -->
            <i class="el-icon-edit" @click="edit(scope.$index,scope.row)" style="color:#1890ff;margin-right:10px;cursor: pointer;" v-if="!scope.row.editShow"></i>
            <div v-else class="btns">
              <span @click="save(scope.$index,scope.row)">保存</span>
              <span @click="cancel(scope.$index,scope.row)">取消</span>
            </div>
            <el-popover placement="bottom-start" width="180" v-model="scope.row.visible">
              <p>您确认要删除该房间吗？</p>
              <div style="text-align: right; margin: 10px 0 0 0">
                <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="ensureDelete(scope.row.id),scope.row.visible = false">确定</el-button>
              </div>
              <i class="el-icon-delete" slot="reference"></i>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
    <!-- 添加房间弹框 -->
    <el-dialog title="添加房间" :visible.sync="addRomeVisible" width="25%" :lock-scroll="false">
      <el-form :model="roomForm" label-position="top" :rules="rules" ref="roomForm" :hide-required-asterisk="true">
        <el-form-item prop="name" label="房间名称：">
          <el-input v-model="roomForm.name" placeholder="请输入房间名称" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="sceneid" label="所属场景：">
          <el-select v-model="roomForm.sceneid" placeholder="请选择所属场景" style="width:100%" size="small">
            <el-option v-for="item in sceneList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="roomForm.remark" type="textarea" placeholder="请输入备注（选填）" size="small">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addRomeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('roomForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRoomList, editRoom, addRoom, deleteRoom, editType } from '@/api'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      layout: 'prev, pager, next',
      suiteForm: {
        tableData: []
      },
      suiterules: {
        name: [
          { required: true, message: '请输入房间名称', trigger: 'change' }
        ],
        sceneId: [
          { required: true, message: '请选择所属场景', trigger: 'change' }
        ]
      },
      totalList: [],
      total: 10,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        siteid: this.$route.query.siteid
      },
      addRomeVisible: false,
      roomForm: {
        name: '',
        sceneid: '',
        remark: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入房间名称', trigger: 'change' }
        ],
        sceneid: [
          { required: true, message: '请选择所属场景', trigger: 'change' }
        ]
      },
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['sceneList'])
  },
  methods: {
    init () {
      getRoomList(this.$tools.concatParams(this.queryParams)).then(res => {
        res.data.data.map(el => {
          this.sceneList.map(item => {
            if (el.sceneId === item.id) {
              el.sceneName = item.name
            }
          })
        })
        this.totalList = res.data.data
        this.total = res.data.data.length
        this.getList()
      })
    },
    getList () {
      this.suiteForm.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.suiteForm.tableData.push(this.totalList[i])
      }
    },
    edit (index, row) {
      // row.editShow = !row.editShow
      if (sessionStorage.getItem('oldRoomindex')) {
        this.$set(this.suiteForm.tableData[sessionStorage.getItem('oldRoomindex')], 'editShow', false)
        this.$set(this.suiteForm.tableData, sessionStorage.getItem('oldRoomindex'), JSON.parse(sessionStorage.getItem('oldRoomRow')))
      }
      sessionStorage.setItem('oldRoomRow', JSON.stringify(row))
      sessionStorage.setItem('oldRoomindex', index)
      this.$set(this.suiteForm.tableData[index], 'editShow', true)
    },
    save (index, row) {
      this.$refs.suiteForm.validate((valid) => {
        if (valid) {
          const params = {
            id: row.id,
            name: row.name,
            remark: row.remark,
            sceneid: row.sceneId
          }
          editRoom(this.$tools.concatParams(params)).then(() => {
            this.$message.success('房间修改成功')
            this.init()
          })
          sessionStorage.setItem('oldRoomRow', JSON.stringify(row))
          sessionStorage.setItem('oldRoomindex', index)

          this.$set(this.suiteForm.tableData[index], 'editShow', false)
        }
      })

    },
    cancel (index) {
      this.$set(this.suiteForm.tableData, index, JSON.parse(sessionStorage.getItem('oldRoomRow')))
      this.$set(this.suiteForm.tableData[index], 'editShow', false)
    },
    switchChange (roomId) {
      editType(roomId).then(res => {
        this.$message.success(res.data.data)
        this.init()
      }).catch(() => {
        this.init()
      })
    },
    ensureDelete (roomId) {
      deleteRoom(roomId).then(() => {
        this.$message.success('房间删除成功！')
        this.init()
      })
    },
    submitForm () {
      this.$refs.roomForm.validate((valid) => {
        if (valid) {
          const params = {
            ...this.roomForm,
            siteid: this.$route.query.siteid
          }
          addRoom(this.$tools.concatParams(params)).then(() => {
            this.addRomeVisible = false
            this.$message.success('添加房间成功')
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.room {
  ::v-deep .el-table {
    .el-form-item {
      // padding-bottom: 10px;
      margin-bottom: 0;
      .el-form-item__error {
        // bottom: -10px;
        // top: inherit;
        // left: 10px;
        position: relative;
      }
    }
    margin-top: 10px;
    .el-input__inner {
      background: none;
      color: #fff;
      border: 1px solid #30c6fe;
    }
    .btns {
      display: inline-block;
      span {
        font-size: 12px;
        color: #1890ff;
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__body {
      .el-form-item__label {
        padding-bottom: 5px;
        line-height: 1;
      }
    }
  }
  .basepagination {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
